// CSS used for landing page / (home page)
import './css/app.less' // for header / footer
import './css/pages/landing.less' // for the landing page

// JS for video on the landing page

function ready(fn) {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

// wait until page is ready...
ready(function () {
  const videoButton = document.querySelector('.video-button')
  const xButton = document.querySelector('.x-icon-container')
  const videoContainer = document.querySelector('.video-container')
  const video = document.querySelector('.ppme-video')

  videoButton.addEventListener('click', function () {
    videoContainer.classList.add('fade')
    videoContainer.style.display = 'block'

    // play video after short delay
    setTimeout(function () {
      video.play()
    }, 250)
  })

  xButton.addEventListener('click', function (err) {
    if (err.target === video) {
      return
    }

    video.pause()
    videoContainer.classList.remove('fade')
    videoContainer.style.display = 'none'
  })
})
